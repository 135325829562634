import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-055d2357"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_simple_date_popover = _resolveComponent("simple-date-popover")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_b_advanced_table, {
          id: "transactions-table",
          "table-array": [_ctx.allTransactionsTable],
          "sticky-header": "calc(100vh - 100px)",
          loading: _ctx.state.loading,
          "dynamic-columns": "",
          "side-pane-options": {
                    hideHeader: true,
                }
        }, {
          toprow: _withCtx(() => [
            _createVNode(_component_b_button, {
              size: "sm",
              variant: "tertiary",
              style: {"width":"200px"},
              onClick: _ctx.openNewTransaction
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.newTransaction')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          action: _withCtx((row) => [
            _createVNode(_component_b_dropdown, {
              "no-caret": "",
              "close-on-click": ""
            }, {
              "button-content": _withCtx(() => [
                _createVNode(_component_faicon, { icon: "ellipsis-h" })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_b_dropdown_item, {
                  size: "sm",
                  onClick: () => _ctx.openTransaction(row.item)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getTransactionActionText(row.item)), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_b_dropdown_item, {
                  disabled: _ctx.isPrintDisabled(row.item.status),
                  onClick: () => _ctx.getTransactionTicket(row.item)
                }, {
                  default: _withCtx(() => [
                    (_ctx.isPickTicket(row.item.status))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.printPickTicket')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.isPackList(row.item.status))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.printPackList')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"]),
                _createVNode(_component_b_dropdown_item, {
                  disabled: _ctx.isPrintDisabled(row.item.status),
                  onClick: () => _ctx.getInboundReceipt(row.item)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.printInboundReceipt')), 1)
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"]),
                _createVNode(_component_b_dropdown_item, {
                  disabled: _ctx.isBillOfLadingDisabled(row.item.status),
                  onClick: () => _ctx.getTransactionBillOfLading(row.item)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.printBillOfLading')), 1)
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"]),
                _createVNode(_component_b_dropdown_item, {
                  disabled: !row.item.isShippingToDifferentCountry,
                  onClick: () => _ctx.getBorderCrossingReport(row.item)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.printProforma')), 1)
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"]),
                _createVNode(_component_b_dropdown_item, {
                  disabled: _ctx.isCancelDisabled(row.item),
                  onClick: () => _ctx.openCancel(row.item)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.cancelOrder')), 1)
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"]),
                (_ctx.Permissions.ADMINISTRATION.canDeleteTransaction())
                  ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                      key: 0,
                      disabled: _ctx.isDeleteDisabled(row.item),
                      onClick: () => _ctx.openDelete(row.item)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.deleteTransaction')), 1)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_b_dropdown_item, {
                  onClick: () => _ctx.openTransactionHistory(row.item)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.showHistory')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]),
          "cell(shipDate)": _withCtx((row) => [
            (row.item.shipDate)
              ? (_openBlock(), _createBlock(_component_simple_date_popover, {
                  key: 0,
                  date: row.item.shipDate
                }, null, 8, ["date"]))
              : _createCommentVNode("", true)
          ]),
          "cell(createdOn)": _withCtx((row) => [
            (row.item.createdOn)
              ? (_openBlock(), _createBlock(_component_simple_date_popover, {
                  key: 0,
                  date: row.item.createdOn
                }, null, 8, ["date"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["table-array", "loading"])
      ])
    ]),
    _: 1
  }))
}