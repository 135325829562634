
import {
    computed, defineComponent, h, onBeforeMount,
} from 'vue';
import Screen from '@/components/layout/Screen.vue';
import { ColumnDefinition, TableDefinition } from '@/types';
import TransactionDigest from '@/domain/TransactionDigest';
import TransactionService from '@/services/TransactionService';
import coreStore from '@/store/CoreStore';
import Permissions from '@/services/permissions/Permissions';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import router from '@/router';
import BButton from '@/components/bootstrap-library/BButton.vue';
import {
    isPackList, isPickTicket, isPrintDisabled, isBillOfLadingDisabled,
} from '@/functions/PrintTransaction';
import TransactionAdvancedFilters from '@/components/filters/TransactionAdvancedFilters.vue';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import { Formatter, getAllStatuses, TransactionStatus } from '@/domain/TransactionStatus';
import FilteredTransactionSearch from '@/dtos/filters/FilteredTransactionSearch';
import { useFilterSearch } from '@/composable/useFilterSearch';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import { TableSortObject } from '@/components/bootstrap-library/table/BTable/composables/useTableSort';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import SimpleDatePopover from '@/components/SimpleDatePopover.vue';
import WarningIcon from '@/components/WarningIcon.vue';
import EntityType from '@/domain/enums/EntityTypes';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'master-data-transaction-list',
    components: {
        BDropdown,
        BDropdownItem,
        Screen,
        BButton,
        BAdvancedTable,
        SimpleDatePopover,
    },
    setup() {
        const transactionService = new TransactionService();
        const { userLocation } = coreStore.getInstance().profileStore;
        const { confirm } = useDialogBox();

        const {
            filterState, submit: executeSearch, reset: resetFilter, state, isFilterDirty, isFilterDefault, recordCountWarning,
        } = useFilterSearch<TransactionDigest, FilteredTransactionSearch>({
            tableKey: 'masterDataTransactionList',
            searchFunction: transactionService.getFilteredTransactions.bind(transactionService),
            defaultFilters: new FilteredTransactionSearch({
                statuses: [
                    ...getAllStatuses()
                        .filter((status) => status !== TransactionStatus.PLANNED && status !== TransactionStatus.DELETED)
                        .map((x) => ({
                            name: Formatter.GetFriendlyValue(x),
                            value: x,
                        })),
                ],
            }),
        });

        onBeforeMount(() => {
            executeSearch();
        });

        const columnDefinition: Array<ColumnDefinition<TransactionDigest>> = [
            {
                key: 'id',
                label: getTitleCaseTranslation('core.domain.transactionNumber'),
                searchable: true,
                width: '120px',
            },
            {
                key: 'fromLocationName',
                label: getTitleCaseTranslation('core.common.fromLoc'),
                searchable: true,
            },
            {
                key: 'toLocationName',
                label: getTitleCaseTranslation('core.common.toLoc'),
                searchable: true,
            },
            {
                key: 'createdOn',
                label: getTitleCaseTranslation('core.domain.placementDate'),
            },
            {
                key: 'transactionStatusDescription',
                label: getTitleCaseTranslation('core.domain.status'),
                searchable: true,
            },
            {
                key: 'shipDate',
                label: getTitleCaseTranslation('core.domain.shipDate'),
                searchable: true,
            },
            {
                key: 'carrier',
                label: getTitleCaseTranslation('core.domain.carrier'),
                searchable: true,
                hidden: true,
            },
            {
                key: 'carrierReferenceNumber',
                label: getTitleCaseTranslation('core.domain.carrierReferenceNumber'),
                searchable: true,
                hidden: true,
            },
            {
                key: 'partnerReferenceNumber',
                label: getTitleCaseTranslation('core.domain.partnerReferenceNumber'),
                searchable: true,
                hidden: true,
            },
            {
                key: 'sealNumber',
                label: getTitleCaseTranslation('core.domain.sealNumber'),
                searchable: true,
                hidden: true,
            },
            {
                key: 'trailer',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.trailer'),
            },
            {
                key: 'licensePlateNumber',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.licenseNumberShort'),
            },
            {
                key: 'originalTransactionId',
                searchable: true,
                label: getTitleCaseTranslation('core.domain.originalTransactionNumber'),
                hidden: true,
            },
        ];

        const defaultSort: Array<TableSortObject> = [{ key: 'id', direction: 'descending' }];

        const allTransactionsTable = computed(
            (): TableDefinition<TransactionDigest> => ({
                items: state.resultSet,
                key: 'masterDataAllTransactionList',
                name: getTitleCaseTranslation('core.selectOptions.allTransactions'),
                columnDefinition,
                headIcon: () => h(WarningIcon, {
                    message: recordCountWarning.value,
                }),
                sidePane: () => h(TransactionAdvancedFilters, {
                    onSubmit: executeSearch,
                    onReset: resetFilter,
                    modelValue: filterState.value,
                    disabled: state.loading,
                    filterDirty: isFilterDirty.value,
                    filterDefault: isFilterDefault.value,
                }),
            }),
        );

        function isCancelDisabled(transactionDigest: TransactionDigest) {
            return !transactionDigest.canCancel(userLocation);
        }

        function isDeleteDisabled(transactionDigest: TransactionDigest) {
            return !transactionDigest.canDelete(userLocation);
        }

        function openTransaction(data: TransactionDigest) {
            router.push({
                name: MasterDataRouteTypes.TRANSACTION.EDIT,
                params: { transactionId: data.id.toString() },
            });
        }

        async function getTransactionTicket(data: TransactionDigest) {
            state.loading = true;
            await transactionService.getTransactionTicketById(data.id, data.status, data.actualDepartureDate, data.plannedDepartureDate);
            state.loading = false;
        }

        async function getTransactionBillOfLading(data: TransactionDigest) {
            state.loading = true;
            await transactionService.getBillOfLading(data.id);
            state.loading = false;
        }

        async function getBorderCrossingReport(data: TransactionDigest) {
            state.loading = true;
            await transactionService.getBorderCrossingReport(data.id);
            state.loading = false;
        }

        async function getInboundReceipt(data: TransactionDigest) {
            state.loading = true;
            await transactionService.getInboundReceipt(data.id, data.arrivedAt);
            state.loading = false;
        }

        function openNewTransaction() {
            router.push({
                name: MasterDataRouteTypes.TRANSACTION.ADD_NEW_TRANSACTION,
            });
        }

        function getTransactionActionText(transaction: TransactionDigest): string {
            if (Permissions.ADMINISTRATION.canCloseoutDisputedTransactions() && transaction.status === TransactionStatus.DISPUTED) {
                return getTitleCaseTranslation('core.common.closeout');
            }
            if (Permissions.ADMINISTRATION.canEditTransactions() && (transaction.canAdminEdit(userLocation) || transaction.canAdminEditDockAndTimeslot(userLocation))) {
                return getTitleCaseTranslation('core.button.edit');
            }
            if (Permissions.ADMINISTRATION.canAdjustConfirmed() && transaction.canAdminAdjust()) {
                return getTitleCaseTranslation('core.domain.adjust');
            }

            return getTitleCaseTranslation('core.button.view');
        }

        async function openCancel(transactionDigest: TransactionDigest) {
            const isConfirmedResponse = await confirm({
                title: getTitleCaseTranslation('core.button.cancelOrder'),
                message: getTranslation('core.common.areYouSureYouWantToCancelThisTransaction'),
            });

            if (isConfirmedResponse) {
                const response = await transactionService.cancelTransactionById(transactionDigest.id);
                if (response) {
                    state.resultSet = state.resultSet.filter((t) => t.id !== transactionDigest.id);
                }
            }
        }

        async function openTransactionHistory(transactionDigest: TransactionDigest) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.TRANSACTION, entityId: transactionDigest.id },
            });
        }

        async function openDelete(transactionDigest: TransactionDigest) {
            const isConfirmedResponse = await confirm({
                title: getTitleCaseTranslation('core.button.deleteTransaction'),
                message: getTranslation('core.common.areYouSureYouWantToDeleteThisTransaction'),
            });

            if (isConfirmedResponse) {
                const response = await transactionService.deleteTransactionById(transactionDigest.id);
                if (response) {
                    state.resultSet = state.resultSet.filter((t) => t.id !== transactionDigest.id);
                }
            }
        }

        return {
            allTransactionsTable,
            state,
            Permissions,
            openTransaction,
            getTransactionTicket,
            getTransactionBillOfLading,
            openNewTransaction,
            isPrintDisabled,
            isBillOfLadingDisabled,
            getTransactionActionText,
            isPackList,
            isPickTicket,
            openCancel,
            openTransactionHistory,
            isCancelDisabled,
            defaultSort,
            getBorderCrossingReport,
            getInboundReceipt,
            getTitleCaseTranslation,
            isDeleteDisabled,
            openDelete,
        };
    },
});
